// Do not change order of this enum

/* eslint-disable typescript-sort-keys/string-enum */
export enum MembershipLevel {
  MDRT = 'MDRT',
  COT = 'COT',
  TOT = 'TOT',
}

export enum MembershipTypeId {
  QUALIFYING = 10,
  QUALIFYING_AND_LIFE = 11,
  LIFE = 12,
}
