import { sendGTMEvent } from '@next/third-parties/google';

import { GTM_ACTION_CLICK, GTM_EVENT_USER_INTERACTIONS } from '../constants';

type UserInteractionsEventType = {
  action?: string;
  author_bio_type?: string;
  carousel_header?: string;
  event?: string;
  eventName?: string;
  label?: string;
  link_label?: string;
  link_url?: string;
  new_value?: number;
  old_value?: number;
  searchResultsValues?: object;
};

export const EMPTY_SEARCH_RESULTS_VIEW_EVENT_DATA = {
  q_pageNumber: undefined,
  q_selectedTerms: undefined,
  search_term: undefined,
};

export const sendGtmEvent = ({
  action,
  event,
  eventName,
  label,
  link_label,
  new_value,
  old_value,
  searchResultsValues,
  author_bio_type,
  carousel_header,
  link_url,
}: UserInteractionsEventType) =>
  sendGTMEvent({
    action: action || GTM_ACTION_CLICK,
    author_bio_type: author_bio_type || undefined,
    carousel_header: carousel_header || undefined,
    event: event || GTM_EVENT_USER_INTERACTIONS,
    eventName: eventName || undefined,
    label: label || undefined,
    link_label: link_label || undefined,
    link_url: link_url || undefined,
    new_value: new_value ?? undefined,
    old_value: old_value ?? undefined,
    ...(searchResultsValues || EMPTY_SEARCH_RESULTS_VIEW_EVENT_DATA),
  });
