import * as React from 'react';
import type { SVGProps } from 'react';
const SvgLockIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M6 22q-.824 0-1.412-.587A1.93 1.93 0 0 1 4 20V10q0-.825.588-1.412A1.93 1.93 0 0 1 6 8h1V6q0-2.075 1.463-3.537T12 1q2.074 0 3.538 1.463T17 6v2h1q.825 0 1.413.588T20 10v10q0 .825-.587 1.413A1.92 1.92 0 0 1 18 22zm0-2h12V10H6zm6-3q.825 0 1.413-.587A1.92 1.92 0 0 0 14 15q0-.825-.587-1.412A1.93 1.93 0 0 0 12 13a1.9 1.9 0 0 0-1.412.588q-.585.59-.588 1.412a1.92 1.92 0 0 0 .588 1.413q.591.591 1.412.587M9 8h6V6q0-1.25-.875-2.125A2.9 2.9 0 0 0 12 3q-1.25 0-2.125.875A2.9 2.9 0 0 0 9 6z"
    />
  </svg>
);
export default SvgLockIcon;
