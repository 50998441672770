import classNames from 'classnames';

import AddPhotoIcon from '../svgs/add-photo-icon';

import styles from './profile-photo.module.scss';

export type ProfilePhotoContentType = {
  pictureDescription: string;
};

export type ProfilePhotoProps = {
  actionLabelText?: string;
  cmsContent: ProfilePhotoContentType;
  hasBorder?: boolean;
  hasHoverOverlay?: boolean;
  imageSrc: string | undefined;
  isFluid?: boolean;
};

const PARENT_CLASSNAME = 'profile-photo';

export const ProfilePhoto = ({
  actionLabelText,
  cmsContent,
  hasBorder = true,
  hasHoverOverlay,
  imageSrc,
  isFluid = false,
}: ProfilePhotoProps) => {
  const imageAltText = hasHoverOverlay
    ? `${cmsContent.pictureDescription}. ${actionLabelText}`
    : cmsContent.pictureDescription;

  const renderOverlay = (
    <div
      aria-hidden={hasHoverOverlay}
      className={classNames(styles[`${PARENT_CLASSNAME}__content`], {
        [styles[`${PARENT_CLASSNAME}__content--hover`]]: hasHoverOverlay,
      })}
      data-testid="photo-placeholder-overlay"
    >
      <AddPhotoIcon className={styles[`${PARENT_CLASSNAME}__icon`]} />
      <span>{actionLabelText}</span>
    </div>
  );

  return (
    <div
      className={classNames(styles[`${PARENT_CLASSNAME}`], {
        [styles[`${PARENT_CLASSNAME}--is-fluid`]]: isFluid,
        [styles[`${PARENT_CLASSNAME}--has-border`]]: hasBorder,
      })}
    >
      {imageSrc ? (
        <>
          <div
            className={styles[`${PARENT_CLASSNAME}__content`]}
            data-testid="photo-placeholder-content"
          >
            <img
              alt={imageAltText}
              className={styles[`${PARENT_CLASSNAME}__image`]}
              data-testid="photo-placeholder-image"
              src={imageSrc}
            />
          </div>
          {hasHoverOverlay && renderOverlay}
        </>
      ) : (
        renderOverlay
      )}
    </div>
  );
};
