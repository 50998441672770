import * as React from 'react';
import type { SVGProps } from 'react';
const SvgHomeWorkIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M17 9h2V7h-2zm0 4h2v-2h-2zm0 4h2v-2h-2zM1 21V11l7-5 7 5v10h-5v-6H6v6zm16 0V10l-7-5.05V3h13v18z"
    />
  </svg>
);
export default SvgHomeWorkIcon;
