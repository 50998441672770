import { logErrorMessage } from '@shared/utils';

import { OrgSharedDataTag } from '../constants/tags';

type FetchOptions = {
  cache?: RequestCache;
  next?: NextFetchRequestConfig;
};

type RequestInit = {
  headers: (HeadersInit & FetchOptions) | FetchOptions;
};

export const gqlFetcher = <TData, TVariables>(
  query: string,
  variables?: TVariables,
  options?: RequestInit['headers']
) => {
  return async (): Promise<TData> => {
    const { next, cache, ...restOptions } = options || {};

    try {
      const response = await fetch(`https://cg.optimizely.com/content/v2`, {
        body: JSON.stringify({ query, variables }),
        cache:
          process.env.DISABLE_OPTIMIZELY_GRAPHQL_DATA_FETCH_CACHE === 'true'
            ? 'no-store'
            : undefined,
        headers: {
          Authorization:
            'Basic ' +
            btoa(
              process.env.OPTIMIZELY_GRAPHQL_APP_KEY + ':' + process.env.OPTIMIZELY_GRAPHQL_SECRET
            ),
          'cg-roles': ['Everyone', 'Administrators'] as unknown as string,
          'Content-Type': 'application/json',
          ...restOptions,
        },
        method: 'POST',
        next: {
          ...next,
          revalidate:
            process.env.DISABLE_OPTIMIZELY_GRAPHQL_DATA_FETCH_CACHE === 'false'
              ? 604800
              : undefined,
          tags: [...(next?.tags ? next.tags : []), OrgSharedDataTag.GET_OPTIMIZELY_CONTENT],
        },
      });

      if (!response.ok) {
        logErrorMessage(
          `gql-fetcher fetch returned invalid response code ${
            response.status
          } ${await response.text()}`
        );
      }

      const json = await response.json();

      return json.data;
    } catch (error) {
      logErrorMessage(`gql-fetcher failed to parse as json or error was returned: ${error}`);
    }

    // TODO: find better solution to handle return if response.status !== 200
    return {} as TData;
  };
};
