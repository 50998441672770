import { type ChangeEvent } from 'react';

export const allowedRegExpTransform = (regexp: RegExp) => ({
  input: (value: string) => {
    return typeof value === 'string' ? value.replace(regexp, '') : value;
  },
  output: (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    return value.replace(regexp, '');
  },
});
