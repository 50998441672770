import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNoPhoto = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      d="m29.333 25.5-2.666-2.667v-13.5h-5.4l-2.434-2.666h-5.666l-1.267 1.4-1.9-1.9L12 4h8l2.467 2.667h4.2q1.099 0 1.883.783.783.783.783 1.883zm-24 2.5a2.57 2.57 0 0 1-1.883-.783 2.57 2.57 0 0 1-.783-1.884v-16q0-1.099.783-1.883a2.57 2.57 0 0 1 1.883-.783H6.7l2.667 2.666H5.333v16h20.034L28.033 28zm15.534-7.167a6.3 6.3 0 0 1-2.084 1.817q-1.249.684-2.783.683-2.5 0-4.25-1.75T10 17.333q0-1.533.683-2.783a6.3 6.3 0 0 1 1.817-2.083l1.933 1.933a3.4 3.4 0 0 0-1.283 1.2 3.2 3.2 0 0 0-.483 1.733q0 1.401.966 2.367.967.966 2.367.967.967 0 1.733-.484.767-.483 1.2-1.283zm-.6-7.766a5.5 5.5 0 0 1 1.283 1.9q.45 1.1.45 2.366v.4q0 .2-.033.4L15.2 11.367q.2-.034.4-.034h.4q1.267 0 2.367.45t1.9 1.284m7.033 18L.9 4.667l1.9-1.9 26.4 26.4z"
    />
  </svg>
);
export default SvgNoPhoto;
