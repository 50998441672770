export enum Locale {
  En = 'en',
  Es = 'es',
  Id = 'id',
  Ja = 'ja',
  Ko = 'ko',
  Th = 'th',
  Vi = 'vi',
  ZhCHT = 'zh-cht',
  ZhCN = 'zh-cn',
  ZhHans = 'zh-hans',
  ZhHant = 'zh-hant',
}
