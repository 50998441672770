import { type PropsWithChildren, type ReactNode } from 'react';

import { type ProfilePhotoProps, ProfilePhoto } from '../profile-photo/profile-photo';

import styles from './profile-photo-with-badge.module.scss';

const ProfilePhotoContainerComponent = ({ children }: PropsWithChildren) => (
  <div className={styles['profile-photo-container']} data-testid="profile-photo-container">
    {children}
  </div>
);

export const ProfilePhotoWithBadge = ({
  badge,
  handleModalOpen,
  profilePhotoProps,
  ...restTriggerProps
}: {
  badge?: ReactNode;
  handleModalOpen?: () => void;
  profilePhotoProps: ProfilePhotoProps;
}) => {
  const BadgeComponent = badge && (
    <div className={styles['profile-photo-container__badge']} data-testid="profile-photo-badge">
      {badge}
    </div>
  );

  return (
    <>
      {handleModalOpen ? (
        <ProfilePhotoContainerComponent>
          <button
            {...restTriggerProps}
            className={styles['profile-photo-container__button']}
            data-testid="profile-photo-container-button"
            onClick={() => {
              handleModalOpen();
            }}
            type="button"
          >
            <ProfilePhoto {...profilePhotoProps} />
          </button>
          {BadgeComponent}
        </ProfilePhotoContainerComponent>
      ) : (
        <ProfilePhotoContainerComponent>
          <ProfilePhoto {...profilePhotoProps} hasHoverOverlay={false} />
          {BadgeComponent}
        </ProfilePhotoContainerComponent>
      )}
    </>
  );
};
