import { CUSTOMER_PROFILE_MIN_AGE, CUSTOMER_PROFILE_MAX_AGE } from '../constants';

export const verifyAge = (birthday: string) => {
  const todayDate = new Date();
  const birthDate = new Date(birthday);
  const ageNow = todayDate.getFullYear() - birthDate.getFullYear();

  return ageNow >= CUSTOMER_PROFILE_MIN_AGE && ageNow <= CUSTOMER_PROFILE_MAX_AGE
    ? ageNow
    : undefined;
};
