import { QueryClient } from '@tanstack/react-query';

import { type LocalPathsQuery, useLocalPathsQuery } from './graphql/_generated/gql-generated';

export const getLocalPaths = async () => {
  const queryClient = new QueryClient();

  const data = await queryClient.fetchQuery<LocalPathsQuery>({
    queryFn: useLocalPathsQuery.fetcher(),
    queryKey: useLocalPathsQuery.getKey(),
  });

  const items = data?.SiteSettingsPage?.items?.[0];

  return {
    accountSettingsPagePath: items?.AccountSettingsPageReference?.Expanded?.RelativePath || '',
    adminDashboardPagePath: items?.AdminDashboardPage?.Expanded?.RelativePath || '',
    companyAdminDashboardPagePath: items?.CompanyAdminDashboardPage?.Expanded?.RelativePath || '',
    dashboardPagePath: data?.HomePageV2?.items?.[0]?.RelativePath || '',
    learnPagePath: items?.LearnLandingPage?.Expanded?.RelativePath || '',
    memberDirectoryPath: data?.MemberDirectoryPage?.items?.[0]?.RelativePath || '',
    memberDirectoryProfilePath: data?.PublicProfilePage?.items?.[0]?.RelativePath || '',
    membershipApplicationPagePath: items?.MembershipRenewalV2Page?.Expanded?.RelativePath || '',
    quizPagePath: data?.PersonQuizPage?.items?.[0]?.RelativePath || '',
    searchPagePath: items?.SearchPageV2Reference?.Expanded?.RelativePath || '',
  };
};

export type LocalPaths = Awaited<Promise<PromiseLike<ReturnType<typeof getLocalPaths>>>>;
