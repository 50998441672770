import { redirect } from '../../i18n/i18n-navigation';
import { ERROR_PATH } from '../constants/local-paths';
import { fetchData } from '../helpers/fetch-data';
import { getPublicEnvironmentVariables } from '../helpers/get-public-environment-variables';
import { fetchWithErrorHandling, withServerSideErrorHandling } from '../http-services';
import { type State } from '../types';

import { OrgSharedDataTag } from './constants/tags';

export const getCountryStates = async ({ countryId }: { countryId: Nullable<number> }) => {
  const environment = await getPublicEnvironmentVariables(); // todo add error handling for server action

  return await fetchWithErrorHandling<State[]>(
    `${environment.apiBaseUrl}/${environment.profileRoute}/States/${countryId}`,
    {
      headers: {
        'Ocp-Apim-Subscription-Key': environment.ocpApiSubscriptionKey ?? '',
      },
      next: {
        tags: [
          OrgSharedDataTag.GET_COUNTRY_STATES,
          `${OrgSharedDataTag.GET_COUNTRY_STATES}_${countryId}`,
        ],
      },
    }
  );
};

export const getCountryStatesOnServer = async ({
  countryId,
}: {
  countryId: Nullable<number>;
}): Promise<State[] | undefined> => {
  const response = await withServerSideErrorHandling(
    async () =>
      await fetchData({
        apiUrl: `${process.env.NEXT_PUBLIC_API_BASE_URL}/${process.env.NEXT_PUBLIC_API_PROFILE_ROUTE}/States/${countryId}`,
        headers: {
          'Ocp-Apim-Subscription-Key': process.env.NEXT_PUBLIC_OCP_API_SUBSCRIPTION_KEY ?? '',
        },
        next: {
          tags: [
            OrgSharedDataTag.GET_COUNTRY_STATES,
            `${OrgSharedDataTag.GET_COUNTRY_STATES}_${countryId}`,
          ],
        },
      })
  );

  if (response.ok) {
    return await response.json();
  }

  redirect(ERROR_PATH);
  return undefined;
};
