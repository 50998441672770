export const EDGEAccessGroup = 'EDGE Group';
export const EveryoneAccessGroup = 'Everyone';
export const TOTAccessGroup = 'TOT';

export const EDGEGeoLocationUSA = 'United States';
export const EDGEGeoLocationCanada = 'Canada';

enum EdgeAccessGroupCountry {
  UnitedStates = 222,
  Canada = 36,
}

export const EdgeAccessGroupCountryIds = [
  EdgeAccessGroupCountry.Canada,
  EdgeAccessGroupCountry.UnitedStates,
];

export enum OptimizelyRole {
  Administrators = 'Administrators',
  Authenticated = 'Authenticated',
  CompanyAdministrator = 'Company Administrator',
  EdgeGroup = 'EDGE Group',
  Everyone = 'Everyone',
  IsAnyMember = 'Is Any Member',
  IsCoT = 'Is COT or TOT',
  IsLoggedIn = 'Is Logged In',
  IsMdrtMember = 'Is MDRT Member',
  IsNotLoggedIn = 'Is Not Logged In',
  IsToT = 'Is TOT',
  LearnEditors = 'LearnEditors',
  LearnReaders = 'LearnReaders',
  MdrtStaff = 'MDRT Staff',
  NoMemberHistory = 'No Member History',
  NonMember = 'Non-Member',
  PreviousMember = 'Previous Member',
  WebAdmins = 'WebAdmins',
  WebEditors = 'WebEditors',
}
