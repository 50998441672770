import { isDefined } from './is-defined';

type Designation = {
  ID: number;
  Name: string;
};

export const mapDesignationIdsToLabels = (
  designationIds: number[] | undefined,
  designations: Designation[] | undefined
) => {
  if (
    !designationIds ||
    designationIds.length === 0 ||
    !designations ||
    designations.length === 0
  ) {
    return [];
  }

  return designationIds
    .map((id) => designations.find((designation) => designation.ID === id)?.Name)
    .filter(isDefined);
};
