import { type Provider } from '@auth/core/providers';
import AzureADB2C from '@auth/core/providers/azure-ad-b2c';
import NextAuth from 'next-auth';

import { logException } from '@shared/utils';

import { parseJwtToken } from '../utils';

import { impersonatedPersonIdCookieAction } from './impersonated-person-id-cookie-action';
import { toggleB2cSessionIsActiveCookie } from './toggle-b2c-session-flag-cookie-action';

export enum ADB2CUserGroup {
  Administrators = 'Administrators',
  CompanyAdmin = 'Company Administrator',
  Everyone = 'Everyone',
  MDRTStaff = 'MDRT Staff',
}

const authEnvironmentVariables = {
  B2C_BASE_DOMAIN: process.env['B2C_BASE_DOMAIN'] ?? '',
  B2C_BIOMETRIC_AUTHENTICATION_POLICY: process.env['B2C_BIOMETRIC_AUTHENTICATION_POLICY'] ?? '',
  B2C_BIOMETRIC_DEREGISTER_POLICY: process.env['B2C_BIOMETRIC_DEREGISTER_POLICY'] ?? '',
  B2C_CLIENT_ID: process.env['B2C_CLIENT_ID'] ?? '',
  B2C_CLIENT_SECRET: process.env['B2C_CLIENT_SECRET'] ?? '',
  B2C_CREATE_ACCOUNT_POLICY: process.env['B2C_CREATE_ACCOUNT_POLICY'] ?? '',
  B2C_PASSWORD_RESET_POLICY: process.env['B2C_PASSWORD_RESET_POLICY'] ?? '',
  B2C_POLICY: process.env['B2C_POLICY'] ?? '',
  B2C_REMOVE_SOCIAL_SIGN_IN: process.env['B2C_REMOVE_SOCIAL_SIGN_IN'] ?? '',
  B2C_SOCIAL_SIGN_IN_POLICY: process.env['B2C_SOCIAL_SIGN_IN_POLICY'] ?? '',
  B2C_SSO_URL_BASE: process.env['B2C_SSO_URL_BASE'] ?? '',
  B2C_TENANT_ID: process.env['B2C_TENANT_ID'] ?? '',
};
const B2C_SSO_URL = `${authEnvironmentVariables.B2C_SSO_URL_BASE}/${authEnvironmentVariables.B2C_TENANT_ID}`;

const fetchToken = async (refreshToken: string, b2cPolicy: string) => {
  return await fetch(`${B2C_SSO_URL}/${b2cPolicy}/oauth2/v2.0/token`, {
    body: new URLSearchParams({
      client_id: authEnvironmentVariables.B2C_CLIENT_ID,
      client_secret: authEnvironmentVariables.B2C_CLIENT_SECRET,
      grant_type: 'refresh_token',
      refresh_token: refreshToken,
    }),
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    method: 'POST',
  });
};

export enum AuthProviderName {
  BiometricDeregister = 'b2c-biometric-deregister',
  BiometricRegistration = 'b2c-biometric-registration',
  CreateAccount = 'b2c-create-account',
  PasswordReset = 'b2c-password-reset',
  RemoveSocialSignIn = 'b2c-remove-social-sign-in',
  SignIn = 'azure-ad-b2c',
  SocialSignIn = 'b2c-social-sign-in',
}

const providerPolicy = {
  [AuthProviderName.BiometricRegistration]:
    authEnvironmentVariables.B2C_BIOMETRIC_AUTHENTICATION_POLICY,
  [AuthProviderName.CreateAccount]: authEnvironmentVariables.B2C_CREATE_ACCOUNT_POLICY,
  [AuthProviderName.SignIn]: authEnvironmentVariables.B2C_POLICY,
  [AuthProviderName.PasswordReset]: authEnvironmentVariables.B2C_PASSWORD_RESET_POLICY,
  [AuthProviderName.SocialSignIn]: authEnvironmentVariables.B2C_SOCIAL_SIGN_IN_POLICY,
  [AuthProviderName.BiometricDeregister]: authEnvironmentVariables.B2C_BIOMETRIC_DEREGISTER_POLICY,
  [AuthProviderName.RemoveSocialSignIn]: authEnvironmentVariables.B2C_REMOVE_SOCIAL_SIGN_IN,
};

const getProvider = (providerName: AuthProviderName): Provider => {
  const b2cPolicy = providerPolicy[providerName];

  return {
    ...AzureADB2C({
      allowDangerousEmailAccountLinking: true,
      authorization: {
        params: {
          baseDomain: authEnvironmentVariables.B2C_BASE_DOMAIN,
          scope: `openid offline_access ${authEnvironmentVariables.B2C_CLIENT_ID}`,
          ...(process.env['B2C_VENDOR_CODE'] ? { vendorCode: process.env['B2C_VENDOR_CODE'] } : {}),
        },
        url: `${B2C_SSO_URL}/${b2cPolicy}/oauth2/v2.0/authorize`,
      },
      clientId: authEnvironmentVariables.B2C_CLIENT_ID,
      clientSecret: authEnvironmentVariables.B2C_CLIENT_SECRET,
      issuer: `${B2C_SSO_URL}/v2.0/`,
      token: `${B2C_SSO_URL}/${b2cPolicy}/oauth2/v2.0/token`,
      wellKnown: `${B2C_SSO_URL}/${b2cPolicy}/v2.0/.well-known/openid-configuration`,
    }),
    ...(providerName && { id: providerName }),
  };
};

export const {
  handlers: { GET, POST },
  auth,
  signIn,
  signOut,
} = NextAuth({
  callbacks: {
    async jwt({ account, token, session, profile }) {
      if (profile) {
        token['acr'] = profile['acr'];
      }

      if (session?.personId) {
        token.personId = session?.personId;
        token.personIdBeforeImpersonation = session?.personIdBeforeImpersonation;
      }

      if (session?.acr && !token['acr']) {
        token['acr'] = session?.acr;
      }

      if (account) {
        return {
          ...token,
          accessToken: account.access_token,
          refreshToken: account.refresh_token,
          ...(account.expires_at !== undefined && { expiresAt: account.expires_at }),
        };
      } else if (Date.now() < token.expiresAt * 1000) {
        return token;
      } else {
        try {
          if (token.refreshToken === undefined) {
            throw new Error('No refresh token');
          }

          const response = await fetchToken(
            token['refreshToken'],
            (token['acr'] as string) ?? authEnvironmentVariables.B2C_POLICY.toLowerCase()
          );
          const tokens = await response.json();
          if (!response.ok) throw tokens;

          return {
            ...token,
            accessToken: tokens.access_token,
            expiresAt: Math.floor(Date.now() / 1000 + tokens.expires_in),
            refreshToken: tokens.refresh_token ?? token['refresh_token'],
          };
        } catch {
          return {
            ...token,
            error: 'RefreshAccessTokenError' as const,
          };
        }
      }
    },

    async session({ session, token }) {
      const parsedToken = token.accessToken ? parseJwtToken(token.accessToken) : undefined;

      return {
        ...session,
        accessToken: token['accessToken'],
        acr: token['acr'],
        error: token['error'],
        refreshToken: token['refreshToken'],
        ...(parsedToken !== undefined && {
          groups: parsedToken.groups,
          personId: parsedToken.personId,
        }),
        ...(token?.personId && { personId: token?.personId }),
        personIdBeforeImpersonation: token?.personIdBeforeImpersonation,
      };
    },
  },
  cookies: {
    sessionToken: {
      ...(process.env?.['NEXT_PUBLIC_APP_ENVIRONMENT'] !== 'LOCAL' && {
        name: `__Secure-next-auth.session-token.${
          process.env?.['NEXT_PUBLIC_APP_ENVIRONMENT'] ?? ''
        }`,
      }),
      options: {
        domain:
          process.env?.['NEXT_PUBLIC_APP_ENVIRONMENT'] === 'LOCAL'
            ? 'localhost'
            : process.env?.['AUTH_COOKIES_DOMAIN'],
      },
    },
  },
  events: {
    signIn: () => {
      toggleB2cSessionIsActiveCookie(true);
    },
    signOut: async () => {
      toggleB2cSessionIsActiveCookie(false);
      impersonatedPersonIdCookieAction();
    },
  },
  logger: {
    error: (error) => {
      logException(error);
    },
  },
  pages: {
    error: '/error',
  },
  providers: [
    getProvider(AuthProviderName.SignIn),
    getProvider(AuthProviderName.BiometricRegistration),
    getProvider(AuthProviderName.CreateAccount),
    getProvider(AuthProviderName.PasswordReset),
    getProvider(AuthProviderName.SocialSignIn),
    getProvider(AuthProviderName.BiometricDeregister),
    getProvider(AuthProviderName.RemoveSocialSignIn),
  ],
  trustHost: true,
});
