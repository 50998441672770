'use client';

import classNames from 'classnames';
import { useState } from 'react';

import {
  INITIAL_PAGE_NUMBER,
  MAX_ITEMS_PER_LIST,
  blurActiveElement,
  formatTranslationStringOnClient,
} from '@shared/utils';

import { ArrowIcon } from '../svgs';

import styles from './pagination.module.scss';

type PaginationProps = {
  blurButtonsOnPageChange?: boolean;
  className?: string;
  label?: {
    nextPage: string;
    pagination: string;
    previousPage: string;
    totalItemsCount: string;
  };
  maxItemsPerPage?: number;
  onPageChange: (index: number, blockCondition: boolean, forward?: boolean) => void;
  totalItemsCount?: number;
} & ({ currentPage: number } | { initialPage?: number });

export const Pagination = ({
  blurButtonsOnPageChange,
  className,
  label,
  maxItemsPerPage = MAX_ITEMS_PER_LIST,
  onPageChange,
  totalItemsCount = 0,
  ...rest
}: PaginationProps) => {
  const isControlledComponent = 'currentPage' in rest;

  const [innerCurrentPage, setInnerCurrentPage] = useState(
    isControlledComponent ? undefined : rest.initialPage || INITIAL_PAGE_NUMBER
  );

  const currentPage = (isControlledComponent ? rest.currentPage : innerCurrentPage) as number;

  const totalPagesCount = Math.ceil(totalItemsCount / maxItemsPerPage);
  const endIndex = Math.min(currentPage * maxItemsPerPage, totalItemsCount);
  const startIndex = Math.min((currentPage - 1) * maxItemsPerPage + 1, endIndex);

  const handlePageChange = (index: number, blockCondition: boolean, forward?: boolean) => {
    if (!isControlledComponent) {
      setInnerCurrentPage(index);
    }

    onPageChange(index, blockCondition, forward);
  };

  const handlePrevious = () => {
    if (blurButtonsOnPageChange) blurActiveElement();
    handlePageChange(currentPage === 1 ? 1 : currentPage - 1, currentPage !== 1);
  };

  const handleNext = () => {
    if (blurButtonsOnPageChange) blurActiveElement();
    handlePageChange(Number(currentPage + 1), currentPage !== totalPagesCount, true);
  };

  const formattedItemRange = formatTranslationStringOnClient('{startIndex} - {endIndex}', {
    endIndex,
    startIndex,
  });

  const formattedTotalItemsCount =
    label?.totalItemsCount &&
    formatTranslationStringOnClient(label.totalItemsCount, {
      total: totalItemsCount,
    });

  return (
    <div className={classNames(styles.pagination, className)}>
      <div>
        <span className={styles['pagination__item-range']}>{formattedItemRange}</span>
        {formattedTotalItemsCount}
      </div>
      {totalItemsCount > maxItemsPerPage && (
        <nav aria-label={label?.pagination} className={styles['button-wrapper']}>
          <button
            aria-label={label?.previousPage}
            className={styles.button}
            disabled={currentPage === 1}
            onClick={handlePrevious}
            type="button"
          >
            <ArrowIcon />
          </button>
          <button
            aria-label={label?.nextPage}
            className={styles.button}
            disabled={currentPage === totalPagesCount}
            onClick={handleNext}
            type="button"
          >
            <ArrowIcon className={styles['button__icon-next']} />
          </button>
        </nav>
      )}
    </div>
  );
};
