'use client';

import { useRef } from 'react';

import { useUserContext } from '../../components';
import { postProfilePicture } from '../data/post-profile-picture';

export const useProfilePictureUploadMutation = () => {
  const { accessToken, personId } = useUserContext();
  const abortControllerRef = useRef<AbortController>();

  const mutate = async (formData: BodyInit) => {
    const abortController = new AbortController();
    abortControllerRef.current = abortController;

    return await postProfilePicture({
      abortSignal: abortController.signal,
      accessToken,
      formData,
      personId,
    });
  };

  return {
    cancel: () => abortControllerRef.current?.abort('Request canceled by user'),
    mutate,
  };
};
