'use client';

import { useRef } from 'react';

import { useUserContext } from '../../components';
import {
  patchAssessmentQuestions,
  type PatchAssessmentQuestions,
} from '../data/patch-assessment-questions';

export const useAssessmentQuestionsMutation = () => {
  const { accessToken, personId } = useUserContext();
  const abortControllerRef = useRef<AbortController>();

  const mutate = async (formData: PatchAssessmentQuestions) => {
    const abortController = new AbortController();
    abortControllerRef.current = abortController;

    return await patchAssessmentQuestions({
      abortSignal: abortController.signal,
      accessToken,
      formData,
      personId,
    });
  };

  return {
    cancel: () => abortControllerRef.current?.abort('Request canceled by user'),
    mutate,
  };
};
