import classNames from 'classnames';
import { type PropsWithChildren } from 'react';

import styles from './badge.module.scss';

export enum BadgeVariant {
  Secondary = 'secondary',
  Tertiary = 'tertiary',
}

export type BadgeProps = {
  className?: string;
  isSecondary?: boolean;
  variant?: BadgeVariant;
};

export const Badge = ({
  children,
  className,
  isSecondary,
  variant,
  ...props
}: PropsWithChildren<BadgeProps>) => {
  return (
    <span
      className={classNames(className, styles['badge'], {
        [styles['badge--secondary']]: isSecondary,
        [styles[`badge--${variant}`]]: variant,
      })}
      {...props}
    >
      {children}
    </span>
  );
};

export default Badge;
