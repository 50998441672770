'use client';

import { type PropsWithChildren, createContext, useContext, useMemo } from 'react';

import { type SiteSettingsGlobalContent } from '../../utils/data/get-global-cms-content';
import { type LocalPaths } from '../../utils/data/get-local-paths';

type GlobalCmsContentContextValue = {
  cmsContent: SiteSettingsGlobalContent;
  cmsPaths: LocalPaths;
};

const GlobalCmsContentContext = createContext<GlobalCmsContentContextValue>(
  {} as GlobalCmsContentContextValue
);

export const GlobalCmsContentContextProvider = ({
  children,
  cmsContent,
  cmsPaths,
}: PropsWithChildren<{ cmsContent: SiteSettingsGlobalContent; cmsPaths: LocalPaths }>) => {
  const value = useMemo(
    () => ({
      cmsContent,
      cmsPaths,
    }),
    [cmsContent, cmsPaths]
  );

  return (
    <GlobalCmsContentContext.Provider value={value}>{children}</GlobalCmsContentContext.Provider>
  );
};

export const useGlobalCmsContentContext = () => {
  const value = useContext(GlobalCmsContentContext);

  if (!value) {
    throw new Error(
      `${useGlobalCmsContentContext.name} must be used inside of ${GlobalCmsContentContextProvider.name}`
    );
  }

  return value;
};
